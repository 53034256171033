import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMemberInfo } from "store/member/memberSlice";
import { toast } from "react-hot-toast";
import { useCallback } from "react";
import { GET_DETAIL } from "services/users";
import { formatBirthdate } from "../utils/helper";

const PrivilegeKorean = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const handleCheckActivation = () => {
    onGetDetail()
  };

  const onGetDetail = useCallback(async () => {
    try {
      const res = await GET_DETAIL();
      const data = res?.data[0];
      if (data) {
        const getBirthdate = formatBirthdate(data?.birthdate).split("-");
        dispatch(
          setMemberInfo({
            isMember: true,
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            birthdate: data?.birthdate,
            member_no: data?.member_no,
            rank: data?.rank,
            card_status: data?.card_status,
            day: getBirthdate[0] || "",
            month: getBirthdate[1] || "",
            year: getBirthdate[2] || "",
          })
        );

        if (data?.card_status === "active"){
          navigate("/membership");
        } else {          
          toast.error("User doesn't active!");
          console.error("User doesn't active!");
        }
      } else {                  
        toast.error("User doesn't active!");
        console.error("User doesn't active!");
      }
    } catch (error) {
      toast.error(error.message || "Some error occurred");
      // sent error to sentry
      if (typeof error === "object") {
        console.error("Get user detail info", JSON.stringify(error));
      } else {
        console.error("Get user detail info", error);
      }
    }
  }, [dispatch, navigate]);

  
  return (
    <div className="px-2">
      <div className="justify-center p-2 w-full mt-8 text-sm text-white bg-primary-300">
        ■우대에 대해
      </div>
      <div className="flex flex-col px-2 mt-4 text-sm font-semibold leading-5 text-neutral-700">
        <div className="whitespace-nowrap">◇실버 카드 회원 우대 7% OFF</div>
        <div className="mt-2 whitespace-nowrap">
          ◇골드 카드 회원 우대 10% OFF
        </div>
        <div className="mt-2 leading-5">
          구입 적립 금액 500만엔(세금 별도) 이상으로 골드 카드 회원으로 변경
          가능합니다
        </div>
        <div className="mt-2 leading-5 pl-9">
          ※실버 카드를 수령한 이후부터 적립을 개시합니다 ※VIP 회원 카드 우대 후
          면세도 가능합니다 <br />
          ※다른 우대(주주 우대, 게스트 쿠폰, 포인트 카드 등)와 동시에 이용 하실
          수 없습니다 <br />
          ※상품에 따라 우대 제외품이 있습니다 <br />
          ※상품 구입시에는, 반드시 카드를 제시해 주세요. 구입 금액을 적립해
          드립니다. 또한 계산 후 추후 적립은 불가합니다 <br />
          ※본 서비스는 사용 가능한 6 개 점포에 한합니다
        </div>
      </div>
      <div className="justify-center p-2 w-full mt-8 text-sm text-white bg-primary-300">
        ■회원 서비스 내용
      </div>
      <div className="flex flex-col px-2 mt-4 text-sm font-semibold leading-5 text-neutral-700">
        <div>
          ①우선 면세(한큐 우메다 본점·한큐 맨즈 오사카·한큐 맨즈 도쿄에서만
          가능)
        </div>
        <div className="mt-2 whitespace-nowrap leading-[150%]">
          ②구입 상품 일괄 수취(한큐 우메다 본점에서만 가능)
        </div>
        <div className="mt-2">
          ③이벤트등 정보 발신 서비스(한큐 우메다 본점에서만 비정기적으로 발신)
        </div>
        <div className="mt-2">
          ④수하물 무료 보관(한신 우메다 본점에서만 가능)
        </div>
        <div className="mt-6">
        문의하기
        </div>
        <div className="mt-2 pl-9">
          8-7 Kakuda-cho,Kita-ku,Osaka<br /> 530-8350 Tel:81-6-6361-1381
          <br />Ｅ-mail：hankyu-vip＠
          <a
            href="http://hankyu-hanshin-dept.com/"
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            hankyu-hanshin-dept.com
          </a>
        </div>
      </div>
      <div className="justify-center p-2 w-full mt-8 text-sm text-white bg-primary-300">
        ■각종 서비스 사용 가능 점포
      </div>
      <div className="flex flex-col px-2 mt-4">
        <div className="flex gap-2 justify-between">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow text-sm font-semibold leading-5 whitespace-nowrap text-neutral-700">
            한큐백화점 우메다 본점
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-2">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow text-sm font-semibold leading-5 whitespace-nowrap text-neutral-700">
            한신백화점 우메다 본점
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-2">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow text-sm font-semibold leading-5 whitespace-nowrap text-neutral-700">
            고베 한큐
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-2">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow text-sm font-semibold leading-5 whitespace-nowrap text-neutral-700">
            하카타 한큐
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-2">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow text-sm font-semibold leading-5 whitespace-nowrap text-neutral-700">
            한큐 맨즈 오사카
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-2">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow text-sm font-semibold leading-5 whitespace-nowrap text-neutral-700">
            한큐 맨즈 도쿄
          </div>
        </div>
        <div className="mt-2 text-sm font-semibold leading-5 text-neutral-700">
          ※내용에 따라 일부 이용하실 수 없는 서비스가 있습니다 ※그 외 점포
          서비스는 각 점포의 면세 카운터에 문의해 주십시오
        </div>
      </div>
      <div onClick={handleCheckActivation} className="frame cursor-pointer">
        <div className="text-wrapper">
          {memberInfo?.card_status === "active" ? "Back to home" : "점원이 회원 카드를 활성화합니다."}
        </div>
      </div>
    </div>
  );
};

export default PrivilegeKorean;
