import MembershipContent from "component/MemberAgreement";
import Layout from "./Layout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const MembershipAgreement = () => {
  const navigate = useNavigate();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  
  useEffect(() => {
    if (memberInfo?.card_status === "invalid" && memberInfo?.first_name) {
      navigate("/membership-privilege");
    } else if (memberInfo?.card_status === "invalid") {
      navigate("/");
    } else {
      localStorage.setItem("active", true)
    }

  }, [memberInfo, navigate]);
  
  return (
    <Layout isShowButtonBack={true} isShowButtonHome={false}>
      <div
        className={`max-w-none lg:max-w-mobile m-auto bg-white overflow-hidden grid gap-30 font-primary min-h-screen`}
      >
        <div className="flex flex-col items-center px-2 pb-24 font-semibold bg-primary-100 lg:max-w-mobile">
          <MembershipContent maxContent />
        </div>
      </div>
    </Layout>
  );
};

export default MembershipAgreement;
