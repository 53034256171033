import { Silver, Black, Gold } from "common/constants";
import { useTranslation } from "react-i18next";

const Email = ({ type }) => {
  const { t } = useTranslation();

  return (
    <div className="font-primary">
      <div
        className={`flex flex-col text-sm font-semibold leading-5 text-center ${
          type === "black"
            ? "text-black"
            : type === "silver"
            ? "text-[#848484]"
            : " text-primary-300"
        } whitespace-nowrap`}
      >
        <img
          alt="customer_service"
          loading="lazy"
          srcSet={
            type === "gold"
              ? Gold.email
              : type === "black"
              ? Black.email
              : Silver.email
          }
          className="self-center w-16 aspect-square m-auto"
        />
        <div className="mt-1">{t("customer_service")}</div>
      </div>
      <div className="mt-6 text-sm font- leading-5 text-secondary">
        {t("contact_us")}
        <br />
        <br />
        Ｅ-mail：hankyu-vip＠hankyu-hanshin-dept.com
      </div>
    </div>
  );
};

export default Email;
