import { Silver, Black, Gold } from "common/constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const HandlingService = ({ type }) => {
  const { t } = useTranslation();
  const [language, setlanguage] = useState("en");

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    lang === "kr" ? setlanguage("kr") : setlanguage("en");
  }, [localStorage.getItem("lang")]);

  return (
    <div className="font-primary">
      <div
        className={`flex flex-col text-sm font-semibold leading-5 text-center ${
          type === "black"
            ? "text-black"
            : type === "silver"
            ? "text-[#848484]"
            : " text-primary-300"
        }`}
      >
        <img
          alt="free_luggage"
          loading="lazy"
          srcSet={
            type === "gold"
              ? Gold.freeService
              : type === "black"
              ? Black.freeService
              : Silver.freeService
          }
          className="self-center w-16 aspect-square m-auto"
        />
        <div className="mt-1" dangerouslySetInnerHTML={{ __html: t("free_luggage_black") }}></div>
      </div>

      <div className="max-h-[340px] overflow-x-auto">
      <div className="mt-2 text-sm font-light leading-5 text-neutral-700">
          <div dangerouslySetInnerHTML={{ __html: t("black_desc1") }}></div>
        </div>
        <div className="mt-2 text-sm font-light leading-5 text-neutral-700">
          <div dangerouslySetInnerHTML={{ __html: t("black_desc2") }}></div>
        </div>{" "}
        <div className="mt-2 text-sm font-light leading-5 text-neutral-700">
          <div dangerouslySetInnerHTML={{ __html: t("black_desc3") }}></div>
        </div>
      </div>
    </div>
  );
};

export default HandlingService;
