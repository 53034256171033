//get Color by variant
const getColor = (type) => {
    let color = '';
    switch (type) {
      case 'silver':
        color = '#848484';
        break;
      case 'gold':
        color = '#c69939';
        break;
      case 'black':
        color = '#000000';
        break;
      default:
        color = 'white'; // Use default icon color if intent is undefined
    }
    return color;
  };
  
  export default getColor;
  