// reducers/index.js
import { combineReducers } from 'redux';
import counterReducer from './counter/counterSlice';
import memberReducer from './member/memberSlice';
import invalidMemberReducer from './member/invalidMemberSlice';
import authReducer from './auth/authSlice';

const rootReducer = combineReducers({
  counter: counterReducer,
  member: memberReducer,
  invalidMember: invalidMemberReducer,
  auth: authReducer
  // Other reducers can be added here.
});

export default rootReducer;
