import { Silver, Black, Gold } from "common/constants";
import { useTranslation } from "react-i18next";
const PriorityTax = ({ type }) => {
  const { t } = useTranslation();

  return (
    <div className="font-primary">
      <div
        className={`flex flex-col text-sm font-semibold leading-5 text-center ${
          type === "black"
            ? "text-black"
            : type === "silver"
            ? "text-[#848484]"
            : " text-primary-300"
        } whitespace-nowrap`}
      >
        <img
          alt="priority_tax"
          loading="lazy"
          srcSet={
            type === "gold"
              ? Gold.tax
              : type === "black"
              ? Black.tax
              : Silver.tax
          }
          className="self-center w-16 aspect-square m-auto"
        />
        <div className="mt-1">{t("priority_tax")}</div>
      </div>

      <div className="max-h-[340px] h-[340px] overflow-x-auto">
        <div className="mt-6 text-sm font-light leading-5 text-neutral-700">
          <div
            dangerouslySetInnerHTML={{ __html: t("available_stores_list") }}
          ></div>
        </div>
        <div className="mt-2 text-sm font-light leading-5 text-neutral-700">
          <div dangerouslySetInnerHTML={{ __html: t("store_1") }}></div>
        </div>
        <div className="mt-2 text-sm font-light leading-5 text-neutral-700">
          <div dangerouslySetInnerHTML={{ __html: t("store_2") }}></div>
        </div>{" "}
        <div className="mt-2 text-sm font-light leading-5 text-neutral-700">
          <div dangerouslySetInnerHTML={{ __html: t("store_3") }}></div>
        </div>
      </div>
    </div>
  );
};

export default PriorityTax;
