/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import Dropdown from "component/Dropdown";
import { useNavigate } from "react-router-dom";
import { Silver } from "common/constants";
import "./style.css";

const Login = () => {
  const navigate = useNavigate();
  const [language, setlanguage] = useState("ja");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    setlanguage(lang);
  }, [localStorage.getItem("lang")]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setLoading(false);
    }, 1000);
  }, []);

  const onChangeLang = () => {
    setIsLoading(true);
    setLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setLoading(false);
    }, 1000);
  };

  return (
    <div
      className={`${
        loading
          ? "absolute inset-0 cursor-not-allowed pointer-events-none"
          : "font-primary"
      }`}
    >
      {(isLoading || loading) && (
        <span className="!flex loader">
          <BeatLoader size={20} color={"#404040"} />
        </span>
      )}
      <div
        className={`${
          loading || isLoading
            ? "opacity-20 cursor-not-allowed pointer-events-none"
            : ""
        } w-full lg:max-w-mobile m-auto bg-white overflow-hidden gap-30 font-primary min-h-screen`}
      >
        <div
          className={`flex flex-col gap-10 items-center px-2 pt-8 pb-24 font-semibold bg-white w-full lg:max-w-mobile min-h-screen`}
        >
          <div className="flex w-full">
            <div class={`${"flex ml-auto mr-0 my-0"} gap-4 items-center`}>
              <div class="min-w-0 m-auto"></div>
              <div class="min-w-0 flex justify-end">
                <Dropdown handleChange={onChangeLang} />
              </div>
            </div>
          </div>
          <>
            <img
              alt="card"
              loading="lazy"
              srcSet={Silver.login}
              className="mt-2 w-full scale-90"
            />
            <div
              onClick={() => navigate("/membership-coupon")}
              className="px-4 mt-8 w-full cursor-pointer"
            >
              <h5 class="text-lg font-semibold mb-3.5 font-roboto mt-4 text-center text-stroke-custom">
                {" "}
                Please sign up or login
              </h5>
              <div
                className={`justify-center py-3 self-center text-lg text-center text-white whitespace-nowrap w-11/12 m-auto bg-black rounded-3xl hover:scale-105 hover:cursor-pointer`}
              >
                Email Address
              </div>
            </div>
            <div class="w-full text-center font-normal flex flex-col justify-center px-4 py-0 mb-[3%] mt-[15%] xs500:mt-0">
              <h5 class="text-sm mb-4 leading-4.75 font-roboto mt-4">
                By proceeding, you agree to
                <a
                  class="underline text-blue-500 mx-1"
                  href="https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                and
                <a
                  class="underline text-blue-500 mx-1"
                  href="https://www.upbond.io/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </h5>
              <div class="font-semibold text-sm font-roboto ios text-black text-stroke-black stroke-2 text-stroke-custom">
                Supported By UPBOND
              </div>
            </div>
          </>
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: "toaster",
        }}
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};

export default Login;
