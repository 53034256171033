/* eslint-disable react/jsx-no-comment-textnodes */
import Layout from "./Layout";
import GuestCoupon from "component/GuestCoupon";
import couponImage from "assets/images/coupon.svg";

const NewMemberCoupon = () => {
  return (
    <Layout title="GUEST COUPON" isShowButtonHome={false} column={2}>
      <img
        loading="lazy"
        srcSet={couponImage}
        alt="coupon"
        className="mt-4 w-full max-w-xs aspect-[1.96]"
      />
      <GuestCoupon />
    </Layout>
  );
};

export default NewMemberCoupon;
