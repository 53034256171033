const AgreementEnglish = () => {
  return (
    <>
      <div className="leading-5">
        1. Qualifications for membership and registration process
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        (1) A “member” shall mean an individual who, subject to approving this
        Overseas Customers’ VIP Card Members’ Agreement (hereafter, referred to
        as, the “Agreement”), makes a membership application with Hankyu Hanshin
        Department Stores, Inc. (hereafter, referred to as, the “company”), has
        the membership application approved by the company, and subsequently
        receives an Overseas Customers’ VIP Card (hereafter, referred to as,
        “card”).
        <br /> (2) When a membership application is made, a membership is
        renewed, and/or a card is used, there will be instances in which the
        company will ask to confirm your identity by checking your passport.{" "}
        <br />
        (3) An Overseas Customers’ VIP Card shall be issued only to individuals
        who, during the course of one calendar year, have purchased a total of
        1,000,000 JPY or more in goods (price after various discounts and
        special offers, excluding tax), and completed the tax refund process for
        those goods, at the following four stores combined: Hankyu Department
        Store Umeda Main Store, Hanshin Department Store Umeda Main Store, Kobe
        Hankyu and Hankyu Men’s Osaka; or at Hankyu Men’s Tokyo only; or at
        Hankyu Department Store Hakata Hankyu only
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        2. Issuance and use of cards
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        (1) Issuance of cards and WeChat electronic cards shall be limited to
        one card per person. From the date of issuance, the card can be used at
        Hankyu Department Store Umeda Main Store, Hanshin Department Store Umeda
        Main Store, Kobe Hankyu, Hankyu Men’s Osaka, Hankyu Men’s Tokyo and
        Hankyu Department Store Hakata Hankyu. <br />
        (2) Use of the card shall be limited to the person whose name is listed
        on the back side of the relevant card. Transfer or lending of the card
        is not permitted. If transfer, or lending, of the card is found to have
        taken place, the card shall be invalidated and rejoin may be rejected.{" "}
        <br />
        (3) As a rule, the company will not re-issue cards. (4) Either a card or
        a WeChat electronic card shall be issued, but not both.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        3. No Membership Fees
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        No membership fee, annual fee, or other fee associated with becoming a
        member shall be charged.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        4. Validity period
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        The card shall be valid from the date of issuance until the final day of
        the month that falls three years after the issue date.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">5. Privileges</div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        (1) Please present the card before paying your bill or obtaining a
        service in the departments designated by the company. <br />
        (2) Preferential treatment or privileges shall not apply if you fail to
        present your card. <br />
        (3) The preferential treatment accessible via the card cannot be
        combined with any other preferential treatment options. There are some
        departments in which and to which preferential treatment offers do not
        apply. In addition, even in departments where preferential treatment
        offers do apply generally, preferential treatment offers do not apply to
        goods worth under 1000 JPY (excluding tax), bargain goods, lucky bags,
        gift certificate, restaurants, cafes, and food items. <br />
        (4) The terms and conditions of privileges may be added to, changed, or
        canceled without prior notice.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        6. Process for returning purchased goods
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        Please present the card at the time of returning goods purchased via
        transactions involving the card.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        7. Reissuance of stolen or damaged cards
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        (1) If your card is stolen or damaged, please contact the club promptly.{" "}
        <br />
        (2) When applying for reissuance of a lost or stolen card, please
        present a form of personal identification. <br />
        (3) Your previous card will become invalid from the moment your new card
        is issued.
      </div>
      <div className="mt-4 leading-5">
        8. Notice of changes to personal information
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        If your name, telephone number or other personal information changes,
        please inform the club promptly.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        9. Leaving the club
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        If you wish to cancel your membership, please inform the club promptly
        and also return your card.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        10. Revocation of membership
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        You will have your membership revoked in the following instances: <br />
        ① If the membership renewal process has not been completed before your
        card expires; <br />② If this Agreement is breached or improper actions
        (such as transferring or lending the card) should occur
      </div>
      <div className="mt-4 leading-5">
        11. Additions and changes to this Agreement
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        Notice of changes to this Agreement shall be provided in advance via our
        homepage, in-store notices, and/or other methods, and shall take effect as
        of the date specified.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        12. Termination of a card
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        Should circumstances arise in which our card service needs to end,
        notice of the end date will be provided in advance via our homepage,
        in-store notices, and/or other methods. Please note in advance that you
        will be unable to take advantage of your privileges from this date
        onward.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        13. Legal basis for processing
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        If you reside in the EEA or the UK, as a rule the company processes your
        personal information (hereafter, “personal information”) based on your
        consent. You may withdraw your consent any time and the withdrawal of
        your consent will not affect lawfulness of processing based on consent
        before the withdrawal.
      </div>
      <div className="mt-4 leading-5">
        14. Registration for card membership; management and collection of
        personal information
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        Upon joining the club, you consent to the fact that the company shall
        collect, store, use, alter, transmit, provide, disclose and delete the
        following personal information as necessary for operation and
        administration of the card: <br />① Member information (name, date of
        birth, nationality, telephone number, email address, WeChat ID) <br />②
        Information relating to use of your card (stores at which your card is
        used, items purchased, amounts paid, number of times used)
      </div>
      <div className="mt-4 leading-5">
        15. Purposes for which a member’s personal information shall be used
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        You consent to the fact that any of your personal information gathered
        by the company, as set forth in the previous article, shall be securely
        held and used by all companies within the H2O Retailing Group and Ningbo
        Hankyu Department Stores Co.,Ltd. (“H2O Retailing Group, etc.”),* which
        includes the company, for the following purposes: <br />① To notify the
        club’s operations and associated privileges; 　　　
        <br />② To make various announcements regarding the events, products,
        services and information offered by group companies; 　　　
        <br />③ For marketing activities related to market surveys, product
        development and renewal initiatives; 　　　
        <br />④ In order to contact you to confirm situations in which your card
        has been used. All companies within the H2O Retailing Group, etc. are
        located in Japan, which is decided as a country that has adequate level
        of protection of personal information by European Commission. 　　　
        <br />※ H2O Retailing Group includes H2O Retailing Corporation and the
        Companies listed at the URL below : http://www.h2o-retailing.co.jp/.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        16. Exercise of your rights
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        n instances where, with justifiable reason, and in line with the company’s
        established processes, you request the exercise of your rights related
        to your personal information, as a rule the company shall comply with
        those requests. The rights which you are entitled to exercise depends on
        applicable laws and regulations. A Member residing in the EEA or the UK:{" "}
        <br />
        Access to your personal information Rectification, erasure, or data
        portability of your personal information <br />
        Objection to or restriction on processing of your personal information{" "}
        <br />
        Withdrawal of your consent <br />A member residing in any other
        jurisdictions:
        <br />
        Access to your personal information Rectification or deletion of your
        personal information <br />
        Any other rights under applicable laws and regulations
      </div>
      <div className="mt-4 leading-5">
        17. Suspension of use and provision of personal information
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        In instances where you request the suspension of use or provision of
        your personal information, as a rule the company shall take prompt
        measures to comply with those requests. However, even in those
        instances, the company shall continue to use and provide personal
        information within the parameters deemed necessary for card
        administration, responding to inquiries, and operation and management of
        the card. In such case, legal basis for processing of personal
        information is legitimate interest.
      </div>
      <div className="mt-4 leading-5">
        18. Methods for access to your personal information
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        When requesting the access to your personal information, you must fill in
        the relevant sections of the standard company documents, and submit them
        together with documents that enable the company to confirm your identity,
        such as a passport. You also must allow for a considerable period of
        time between submission of the documents and the actual access to the
        information. Furthermore, there are cases in which the company may
        demand a standard administrative fee for handling such requests.
      </div>
      <div className="mt-4 leading-5">
        19. Refusal to provide your personal information or consent to our
        processing of your personal information
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        Should you fail to fill in our documents and refuse to consent to the
        terms of Article 16 and 17, there may be instances in which the company
        refuses to permit your entry to the club, or may terminate the
        membership. If you reside in the EEA or the UK and request to withdraw
        consent to our processing of your personal information, the company
        shall take prompt measures to comply with such requests. Depending on
        circumstances, there may be instances in which the company terminates
        the membership in accordance with Article 9.
      </div>
      <div className="mt-4 leading-5">
        20. Lodging a complaint with a supervisory authority
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        If you reside in the EEA or the UK, you are entitled to lodge a
        complaint about our processing of your personal information with the
        competent supervisory authority.
      </div>
      <div className="mt-4 leading-5">
        21. Period of retention of personal information
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        If you reside in the EEA or the UK, your personal information will be
        disposed of promptly after the relevant membership is withdrawn or
        terminated
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">22. Inquiries</div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        Inquiries on matters related to this club should be directed via the
        contact details provided below. Hankyu Hanshin Department Store Overseas
        Customers’ VIP Club Tel: 0081-6-6361-1381 Reception hours: 10am-6pm
        (Japan time) <br />※ 1. Please apply only after reading and consenting
        to the terms and conditions of this Agreement. <br /> ※ 2. Articles 1 to
        12 shall take effect as a document based on, and interpreted according to,
        Japanese law. Articles 13 to 22 shall take effect as a document based on,
        and interpreted according to, the Act on the Protection of Personal
        Information of Japan and other applicable laws. The Japanese wording of
        this Agreement shall be taken to be the original vers
      </div>
      <div className="justify-center py-2 mt-4 leading-5 border-t border-b border-solid border-y-black">
        Use of email address/WeChat ID and information distribution service
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        1. Use of email addresses/WeChat IDs
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        The email address/WeChat ID that you register with this club shall be
        used jointly by all entities within H2O Retailing Group, etc. to provide
        better information more efficiently. Joint use of the email address/WeChat
        ID of a member residing in the EEA or the UK shall be performed based
        upon member’s consent. The specific information is described below: <br />
        ① The scope of recipient The email address/WeChat ID are provided to H2O
        Retailing Group, etc. companies <br />② Purpose of provision To present
        information about their new products and services. All operating
        companies have various responsibilities with regard to presenting
        information about new products and services and the processing of
        personal information. However, the company with ultimate responsibility
        for joint use of personal information is Hankyu Hanshin Department
        Stores, Inc.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        2. Email address/WeChat ID management
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        All operating companies have put in place appropriate security measures
        and will store email addresses/WeChat IDs securely. In addition, subject
        to prior approval by the customer, H2O Retailing Group, etc. shall not
        provide or disclose an email address/WeChat ID to third parties, except
        in the following instances: ① When conducting outsourcing for the
        purposes of using the email address/WeChat ID ② When acting in line with
        legal regulations after receiving information disclosure requests from
        public institutions <br />③ When all operating companies deem that such
        an action is necessary in order to protect the lives, bodies, property
        and rights of themselves and third parties.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        3. Information published in emails/WeChat
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        H2O Retailing Group, etc. cannot guarantee the accuracy, usefulness or
        certainty of information published in emails/WeChat (hereafter,
        collectively referred to as, “published information”). Should any use of
        published information result in personal loss or damage to an
        individual, H2O Retailing Group, etc. shall bear no responsibility in
        connection therewith or arising therefrom.
      </div>
      <div className="mt-4 leading-5">
        4. Changes to, and suspension of, email/WeChat
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        H2O Retailing Group, etc. reserves the right to change or suspend the
        makeup and composition of emails/WeChat, terms and conditions of use,
        the URL, published information, and the email/WeChat distribution
        service, in all cases without prior notice.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        5. Copyright issues
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        The content (in terms of HTML emails/WeChat: pictures, images, music and
        voices) published in emails, and other copyrighted items (hereafter,
        collectively referred to as, “content”), as well as rights pertaining to
        logos, trademarks and trade names, belong to H2O Retailing Group, etc.,
        relevant copyright holders, and other rights holders. It therefore
        follows that the printing or saving of such content for public use and,
        except for reasons permissible by law, the copying, forwarding, public
        distribution, alteration, editing, and republishing on any other website
        without the express permission of the relevant operating companies,
        copyright holders, and other rights holders is strictly prohibited by
        law.
      </div>
      <div className="mt-4 whitespace-nowrap leading-[150%]">
        6. Governing law and jurisdiction
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light">
        The items contained in this “use of email address/WeChat ID and
        information distribution service” agreement shall be governed by
        Japanese law. Any dispute that arises over the content of, and
        operational matters relating to, this document shall be adjudicated by
        the Osaka District Court as the court of first instance with exclusive
        jurisdiction.
      </div>
    </>
  );
};

export default AgreementEnglish;
