import { useEffect, useState } from "react";
import PrivilegeEnglish from "./PrivilegeEnglish";
import PrivilegeKorean from "./PrivilegeKorean";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MembershipPrivilege = () => {
  const [language, setlanguage] = useState("en");
  const navigate = useNavigate();

  const memberInfo = useSelector((state) => state.member.memberInfo);
  
  useEffect(() => {    
    if (memberInfo?.card_status === "invalid") {
      localStorage.setItem("active", true)
    } 
  }, [memberInfo, navigate]);

  useEffect(() => {
    setlanguage(localStorage.getItem("lang"));
  }, [localStorage.getItem("lang")]);

  return <>{language === "en" ? <PrivilegeEnglish /> : <PrivilegeKorean />}</>;
};

export default MembershipPrivilege;
