import emailSilverIcon from "assets/images/icon/email-silver.svg";
import emailGoldIcon from "assets/images/icon/email-gold.svg";
import emailBlackIcon from "assets/images/icon/email-black.svg";

import freeServiceSilverIcon from "assets/images/icon/free-service-silver.svg";
import freeServiceGoldIcon from "assets/images/icon/free-service-gold.svg";
import freeServiceBlackIcon from "assets/images/icon/free-service-black.svg";

import memberPrivilegeSilverIcon from "assets/images/icon/member-privileges-silver.svg";
import memberPrivilegeGoldIcon from "assets/images/icon/member-privileges-gold.svg";
import memberPrivilegeBlackIcon from "assets/images/icon/member-privileges-black.svg";

import memberAgreementSilverIcon from "assets/images/icon/member-agreement-silver.svg";
import memberAgreementGoldIcon from "assets/images/icon/member-agreement-gold.svg";
import memberAgreementBlackIcon from "assets/images/icon/member-agreement-black.svg";

import taxSilverIcon from "assets/images/icon/tax-silver.svg";
import taxGoldIcon from "assets/images/icon/tax-gold.svg";
import taxBlackIcon from "assets/images/icon/tax-black.svg";

import cardGoldIcon from "assets/images/icon/card-gold.svg";
import cardSilverIcon from "assets/images/icon/card-silver.svg";
import cardBlackIcon from "assets/images/icon/card-black.svg";
import loginIcon from "assets/images/login.jpg";

import membershipGoldFront from "assets/images/member-gold-front.svg";
import membershipGoldFrontKr from "assets/images/member-gold-front-kr.svg";
import membershipGoldBack from "assets/images/member-gold-back.svg";
import membershipBlackFront from "assets/images/member-black-front.svg";
import membershipBlackFrontKr from "assets/images/member-black-front-kr.svg";
import membershipBlackBack from "assets/images/member-black-back.svg";
import membershipSilverFront from "assets/images/member-silver-front.svg";
import membershipSilverFrontKr from "assets/images/member-silver-front-kr.svg";
import membershipSilverBack from "assets/images/member-silver-back.svg";

export const LOCALSTORAGE = {
  SUBKEY: "__sk__",
  USRRESULT: "__ur__",
  HASHPARAM: "__hp__",
  IDTOKEN: "__it__", // token for guardian & children
  TOKEN: "__token__", // token for guardian & children
  SEED: "__lsd__",
  ADMINJWT: "__aj__", // token for admin idear, school and teacher
  WEB3TOKEN: "__w3t__", // token from web3
  SUB: "__sub__",
};

export const Silver = {
  email: emailSilverIcon,
  freeService: freeServiceSilverIcon,
  memberPrivilege: memberPrivilegeSilverIcon,
  tax: taxSilverIcon,
  memberAgreement: memberAgreementSilverIcon,
  card: cardSilverIcon,
  memberFront: membershipSilverFront,
  memberFrontKr: membershipSilverFrontKr,
  memberBack: membershipSilverFront,
  login: loginIcon,
};

export const Black = {
  email: emailBlackIcon,
  freeService: freeServiceBlackIcon,
  memberPrivilege: memberPrivilegeBlackIcon,
  tax: taxBlackIcon,
  memberAgreement: memberAgreementBlackIcon,
  memberFront: membershipBlackFront,
  memberFrontKr: membershipBlackFrontKr,

  memberBack: membershipSilverFront,
  card: cardBlackIcon,
};

export const Gold = {
  email: emailGoldIcon,
  freeService: freeServiceGoldIcon,
  memberPrivilege: memberPrivilegeGoldIcon,
  tax: taxGoldIcon,
  memberAgreement: memberAgreementGoldIcon,
  memberFront: membershipGoldFront,
  memberFrontKr: membershipGoldFrontKr,
  memberBack: membershipGoldBack,
  card: cardGoldIcon,
};
