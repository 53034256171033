import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AppWithAuth from "App";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "i18n";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/n8n\.upbond\.io\/webhook/,
      ],
    }),
    new CaptureConsole({
      levels: ["error"],
    }),
    new Sentry.Replay(),
  ],
  beforeSend(event, hint) {
    const error = hint.originalException || hint.syntheticException;
    if (error.message) {
      return null;
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate:
    process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppWithAuth />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
