/* eslint-disable react/jsx-no-comment-textnodes */
import Layout from "./Layout";
import MembershipCard from "component/MembershipCard";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Membership = () => {
  const navigate = useNavigate();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  
  useEffect(() => {    
    if (memberInfo?.card_status === "invalid" && memberInfo?.first_name) {
      navigate("/membership-privilege");
    } else if (memberInfo?.card_status === "invalid") {
      navigate("/");
    } else {
      localStorage.setItem("active", true)
    }    

  }, [memberInfo, navigate]);

  return (
    <Layout title="阪急阪神百貨店 VIP会員" isShowButtonHome={false} column={2}>
      <MembershipCard cardType={memberInfo?.rank} />
    </Layout>
  );
};

export default Membership;
