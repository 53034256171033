import { ACTION_GET, ACTION_PATCH, ACTION_POST, ACTION_PUT, BASE_URL } from './config'

export const GET_METADATA = (tokenId) => {  
  const url = `${BASE_URL}/a0d77a52-4c00-4902-9e91-71319b9cc93e/nft-detail/${tokenId}`
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const GET_DETAIL = () => {  
  const url = `${BASE_URL}/vip/user/detail`
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const GET_USER_INFO = () => {  
  const url = `${BASE_URL}/userinfo`
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const POST_USER_INFO = () => {  
  const url = `${BASE_URL}/userinfo`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, null)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const GET_DETAIL_CARD = () => {  
  const url = `${BASE_URL}/vip/usercard/detail`
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const VIP_REGISTER_EMAIL = (params) => {  
  const url = `${BASE_URL}/vip/email/registeration`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const VIP_USER_REGISTER = (params) => {  
  const url = `${BASE_URL}/vip/user/registeration`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const VIP_REGISTER_NAME = (params) => {  
  const url = `${BASE_URL}/vip/name/registeration`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const WITHDRAW_MEMBER = (params) => {  
  const url = `${BASE_URL}/vip/user/withdraw`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const SEND_EMAIL = (params) => {  
  const url = `${BASE_URL}/send/email`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const VERIFY_EMAIL = (params) => {  
  const url = `${BASE_URL}/verify/email`
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}