import { Disclosure, Transition } from "@headlessui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import arrowDown from "assets/images/arrow-down.svg";

const GuestCoupon = (props) => {
  const { type } = props;
  const [isVipMember, setIsVipMember] = useState(true);
  const [isCouponExpired, setIsCouponExpired] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {" "}
      {isVipMember ? (
        <div className="text-sm font-semibold leading-5 text-center text-red-500 max-w-[262px] mt-6">
          {t('vip_member_access')}
        </div>
      ) : isCouponExpired ? (
        <>
          <div className="mt-6 text-sm text-center text-red-500">
            {t('coupon_expired')}
          </div>
          <div className="mt-2 text-sm text-neutral-700">
          {t('expired_date')}：2023/11/8 15:10:05
          </div>
          <div className="text-base text-neutral-700">OKUJI NORIAKI</div>
        </>
      ) : (
        <>
          <div className="mt-6 max-w-full bg-white rounded-lg border border-solid border-zinc-300 h-[100px] w-[300px]" />
          <div className="mt-1 text-sm">000-0-000000</div>
          <div className="mt-2 text-sm whitespace-nowrap">
            {t("expired")}：2023/11/8 15:10:05
          </div>
          <div className="text-base whitespace-nowrap">OKUJI NORIAKI</div>
        </>
      )}
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-center gap-2.5 text-sm font-normal px-5 mt-6">
              {t("about_coupon")}
              <img
                alt="coupon"
                loading="lazy"
                src={arrowDown}
                className="my-auto w-3.5 aspect-[1.41] fill-neutral-700"
              />
            </Disclosure.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>
                <div className="mt-2 text-sm font-light leading-5 px-5 py-2.5">
                  {t("items_departments")}
                  <br />
                  {t("items_description")}
                  <br />
                  {t("present_coupon")}
                  <br />
                  {t("coupon_cannot_used")}
                  <br />
                  {t("coupon_description")}
                  <br />
                  <br />
                  <span className="font-semibold">
                    Stores where the discount coupon can be used
                  </span>
                  <br />
                  Hankyu Umeda Main Store
                  <br />
                  Hanshin Umeda Main Store
                  <br />
                  Hankyu Men's Osaka
                  <br />
                  Hankyu Men's Tokyo
                  <br />
                  Hakata Hankyu
                  <br />
                  Kobe Hankyu
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      <div className="mt-2 text-sm font-light text-center text-black underline whitespace-nowrap">
        {t("tax_exemption")}
      </div>
    </>
  );
};

export default GuestCoupon;
