import { useEffect, useState } from "react";
import AgreementEnglish from "./AgreementEnglish";
import AgreementKorean from "./AgreementKorean";
const ContentAgreement = () => {
  const [language, setlanguage] = useState("en");

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setlanguage(localStorage.getItem("lang"));
    }
  }, [localStorage.getItem("lang")]);
  
  return (
    <>
      <div className="flex flex-col self-stretch mx-auto w-full text-sm font-semibold leading-5 max-w-[480px] text-neutral-700">
        {language === "en" ? (
          <AgreementEnglish />
        ) : (
          <AgreementKorean />
        )}
      </div>
    </>
  );
};

export default ContentAgreement;
