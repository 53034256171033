const AgreementKorean = () => {
  return (
    <>
      <div className="whitespace-nowrap">1.회원 자격 및 가입 절차</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        (1)회원이란 ‘『해외 고객 VIP 카드』회원 규약’(이하 ‘본 규약’이라 한다)을
        숙지한 후, 주식회사
        <br /> 　한큐한신백화점(이하 ‘당사’라고 한다)에 가입 신청을 하고 당사가
        가입을 인정하여 『해외 고객 VIP 　카드』(이하 ‘카드’라고 한다)를 대여한
        개인을 말합니다. <br />
        (2)가입과 갱신 및 카드 이용시에 여권으로 본인 확인을 하는 경우가
        있습니다. <br />
        (3)‘해외 고객 VIP 카드’ 가입은 1년간 한큐 우메다 본점, 한신 우메다 본점,
        고베 한큐,한큐 맨즈 오사카 4점포, 또는 한큐 맨즈 도쿄점 단독, 또는
        하타카 한큐 단독에서 합계 100만 엔(각종 할인 우대 후, 소비세 제외)
        이상을 구매하고 면세 절차를 받은 고객에 한합니다.
      </div>
      <div className="mt-4 whitespace-nowrap">2.카드의 발행과 이용</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        (1)카드 및 WeChat전자 카드의 발행은 한 명당 한 장에 한하며 발행 당일부터
        한큐 우메다 본점, 한신 우메다 본점한큐 맨즈 오사카, 고베 한큐,한큐 맨즈
        도쿄 및 하카타 한큐에서 이용할 수 있습니다. <br />
        (2)카드는 서명된 회원 본인만 이용할 수 있으며 양도 및 대여할 수
        없습니다. 타인에게 양도 및 대여한 사실이 판명되면 카드를 무효로 하며,
        재가입도 거부될 수 있습니다. (3)원칙적으로 카드는 재발행하지 않습니다.{" "}
        <br />
        (4)카드 및 WeChat전자 카드의 발행은 둘 중 한 종류만 가능합니다.
      </div>
      <div className="mt-4">3.회비</div>
      <div className="self-start mt-2 ml-4 text-xs font-light whitespace-nowrap">
        가입비, 연회비, 그 외 회원이 되기 위한 비용은 없습니다.
      </div>
      <div className="mt-4 whitespace-nowrap">4. 유효 기간</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        카드의 유효 기간은 카드 발행일로부터 3년 후의 월말까지입니다.
      </div>
      <div className="mt-4 whitespace-nowrap">5. 특전</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        (1)당사가 지정하는 매장에서 결제 전이나 서비스를 받기 전에 카드를 제시해
        주십시오. <br />
        (2)카드를 제시하지 않으면 우대 및 특전 서비스는 적용되지 않습니다.{" "}
        <br />
        (3)카드를 이용한 우대는 다른 우대 서비스(할인카드, 포인트적립 등) 와
        중복해서 받을 수 없습니다.
        <br />
        또한 우대 제외 대상인 매장이 있습니다. 대상 매장이라도 1,000엔
        미만(소비세 제외)의 상품, 할인 　상품, 후쿠부쿠로(새해 이벤트 상품),
        상품권, 레스토랑 및 카페, 식품은 우대 대상에서 제외됩니다. <br />
        (4)특전의 내용은 예고 없이 추가・변경・종료될 수 있습니다.
      </div>
      <div className="mt-4 whitespace-nowrap">6. 구매 상품 반품 시 처리</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        카드를 이용해 구매한 상품을 반품할 경우에는 반드시 카드를 제시해
        주십시오.
      </div>
      <div className="mt-4 whitespace-nowrap">7. 카드 도난･파손 시 재발행</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        (1)카드를 도난・파손한 경우에는 본 클럽으로 신속히 연락 주십시오.
        <br />
        (2)재발행 때는 반드시 본인을 증명할 수 있는 신분증을 지참해 주십시오.
        <br />
        (3)카드가 재발행된 시점부터 이전에 발행받은 카드는 효력을 잃게 됩니다.
      </div>
      <div className="mt-4 whitespace-nowrap">8. 신청 사항의 변경</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        이름, 전화번호 등 신청 사항이 변경된 경우는 본 클럽으로 신속히 연락
        주십시오.
      </div>
      <div className="mt-4 whitespace-nowrap">9. 탈퇴</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        회원의 사정에 따라 탈퇴할 경우는 본 클럽으로 신속히 연락한 후 카드를
        반납해 주십시오.
      </div>
      <div className="mt-4 whitespace-nowrap">10. 회원 자격의 상실</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        회원은 아래의 경우 회원 자격을 잃게 됩니다. ①카드의 유효 기간 안에
        갱신하지 않은 경우 ②양도 및 대여 등 본 규약을 위반하였거나 부정행위를 한
        경우
      </div>
      <div className="mt-4 whitespace-nowrap">11. 본 규약의 추가 및 변경</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        본 규약을 변경할 경우, 홈페이지나 매장게시 또는 기타 방법을 통해 미리
        변경내용과 변경날짜를 공지할 것이며 해당날짜이후 변경내용이 적용됩니다.
      </div>
      <div className="mt-4 whitespace-nowrap">12. 카드의 종료</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        저희 회사 사정으로 카드의 운용을 종료할 경우, 홈페이지나 매장게시 또는
        기타 방법을 통해 미리 종료날짜를 공지합니다. 이 경우 회원혜택을 이용하실
        수 없게 됩니다. 미리 양해 바랍니다.
      </div>
      <div className="mt-4 whitespace-nowrap">
        13. 카드 회원의 등록, 개인정보의 관리 및 수집
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        회원은 가입 시에 당사가 카드의 운영 및 관리에 필요한 이하의 정보(이하
        ‘개인정보’라 한다) 수집에 동의합니다. ①회원 정보(이름, 생년월일, 국적,
        거주지, 전화번호, 이메일 주소, WeChat ID) ②카드의 이용 내용(이용 점포,
        상품명, 이용 금액, 이용 횟수 등)
      </div>
      <div className="mt-4 whitespace-nowrap">14. 개인정보의 이용 목적</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        회원은 앞 조항에 따라 당사가 수집한 회원의 개인정보를 개인정보
        보호조치를 마련한 후 보유하고 이하의 목적을 위해 당사를 포함한 H2O
        리테일링 그룹 각 사(※)가 이용하는 것에 동의합니다. <br />
        ①본 클럽의 운영 및 특전에 관한 안내를 위해. <br />
        ②각 사의 행사나 상품, 서비스, 정보 등의 각종 안내를 위해. <br />
        ③시장조사, 상품 개발, 리뉴얼 등을 목적으로 한 마케팅 활동을 위해. <br />
        ④카드의 이용 내용 확인 등의 연락을 위해.
        <br /> ※H2O리테일링 그룹 회사의 회사명은
        [http://www.h2o-retailing.co.jp/]를 확인해 주십시오
      </div>
      <div className="mt-4 whitespace-nowrap">
        15. 개인정보의 공개･정정･삭제
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        회원이 정당한 이유로 당사 소정의 절차를 거쳐 자신에 관한 개인정보의
        공개・정정・삭제를 요청한 경우, 당사는 원칙적으로 이에 응합니다.
      </div>
      <div className="mt-4 whitespace-nowrap">
        16. 개인정보의 이용 및 제공 중지
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        회원이 개인정보의 이용 및 제공의 중지를 요청한 경우, 당사는 원칙적으로
        이에 관해 신속한 조치를 합니다. 단, 이 경우에도 당사는 카드의 관리 및
        문의 대응 등 본 카드의 운영 및 관리를 위해 필요한 목적의 범위 안에서
        개인정보를 계속 이용 및 제공합니다.
      </div>
      <div className="mt-4 whitespace-nowrap">17. 개인정보의 공개 방법</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        회원은 자신에 관한 개인정보의 공개를 청구할 경우, 당사 소정의 신청서에
        필요 사항을 기입한 후, 여권 등 자신을 증명할 수 있는 서류와 함께 당사에
        제출해야 합니다. 신청서 제출부터 정보 공개까지는 상당한 시간이 걸릴 수
        있습니다. 또한 이와 같은 청구를 할 때는 사전에 소정의 수수료가 들 수
        있습니다.
      </div>
      <div className="mt-4 whitespace-nowrap">18. 개인정보 취급의 부동의</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        당사는 회원이 당사 소정의 신청을 하지 않는 경우 및 15.16의 규정에
        동의하지 않는 경우, 가입을 거절하거나 탈퇴 절차를 진행할 수 있습니다.
      </div>
      <div className="mt-4 whitespace-nowrap">19. 문의처</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        본 클럽에 관한 문의 및 연락 등은 아래의 연락처로 해 주십시오. 한큐
        우메다 본점 해외고객 VIP 클럽 <br />
        전화번호 0081-6-6361-1381 <br />
        접수 시간 오전 10시부터 오후 6시까지(일본시간) <br />
        ※1. 본 규약을 읽고 동의한 후 신청해 주십시오. <br />
        ※2. 본 규약은 일본어판을 정식으로 삼고 일본법에 준거해 해석되고
        운용됩니다. 일본어가 아닌 언어로 표기된 자료는 번역본입니다.
      </div>
      <div className="justify-center py-2 mt-4 leading-5 border-t border-b border-solid border-y-black">
        이메일 주소/WeChat ID의 이용 및 정보 전송 서비스에 대하여
      </div>
      <div className="mt-4 whitespace-nowrap">
        1. 이메일 주소/WeChat ID의 이용
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        본 클럽에 등록한 이메일 주소/WeChat ID는 주식회사 한큐한신백화점 및 H2O
        리테일링 그룹회사(이하 ‘운영회사 각 사’라고 한다)에서 더 좋은 정보를 더
        효율적으로 제공하기 위해 공동으로 이용합니다. 또한 공동 이용의 내용은
        아래와 같습니다. <br />
        ①운영회사 각 사의 범위운영회사 각 사(주식회사 한큐한신백화점 및 H2O
        리테일링 그룹 회사)의 회사명은 H2O 리테일링 주식회사의
        홈페이지(http://www.h2o-retailing.co.jp/)에서 확인하십시오. <br />
        ②공동으로 이용하는 목적운영회사 각 사의 신상품, 새 서비스 정보의 제공,
        개인정보의 이용은 운영회사 각 사에서 각각 책임을 지고 실시하지만
        공동이용 책임 회사는 주식회사 한큐한신백화점입니다.{" "}
      </div>
      <div className="mt-4 whitespace-nowrap">2. 이메일 주소/WeChat ID</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        운영회사 각 사는 적절한 보안을 확보해 이메일 주소/WeChat ID를 안전하게
        보관합니다. 또한 사전에 고객의 양해를 구한 경우와 아래의 경우를 제외하고
        운영회사 각 사 이외의 제삼자에게 이메일 주소/WeChat ID를 제공 및
        공개하지 않습니다. <br />
        ①이메일 주소/WeChat ID의 이용 목적을 위해 업무위탁을 하는 경우
        <br />
        ②법령 규정에 따라 공적 기관에서 정보 공개 요청을 받은 경우
        <br />
        ③운영회사 각 사 또는 제삼자의 생명･신체･재산･권리 등을 보호하기 위해
        필요하다고 운영회사 각 　사가 판단한 경우
      </div>
      <div className="mt-4 whitespace-nowrap">3. 이메일/WeChat의 게재 정보</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        운영회사 각 사는 이메일/WeChat 전송에 게재된 정보 등(이하 총칭하여 ‘게재
        정보’라고 한다)의 내용에 대하여 그 정확성, 유용성, 확실성, 그 외 어떠한
        보증을 하지 않습니다. 또한 게재 정보의 이용 등에 따라 손해가
        발생했더라도 운영회사 각 사는 일절 책임을 지지 않습니다.
      </div>
      <div className="mt-4 whitespace-nowrap">
        4. 이메일/WeChat 전송의 변경 및 중지
      </div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        운영회사 각 사는 이메일/WeChat 전송의 구성, 이용 조건, URL, 게재 정보,
        이메일/WeChat 전송 서비스를 예고 없이 변경 또는 중지할 수 있습니다.{" "}
      </div>
      <div className="mt-4 whitespace-nowrap">5. 저작권 등</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        운영회사 각 사에서 전송되는 메일/WeChat에 게재된 내용(HTML형식 이메일의
        경우는 사진, 영상, 음악 음성을 포함한다), 그 외 저작물(이하 총칭하여
        ‘콘텐츠’라고 한다), 영업표시, 상표, 상호 등의 권리는 운영회사 각 사 또는
        저작권자, 그 외의 권리자에게 귀속합니다. 따라서 사적으로 이용하기 위해
        인쇄나 보존 등을 하는 경우, 그리고 기타 법률로 인정되는 경우를 제외하고
        콘텐츠를 운영회사 각 사 또는 저작권자 그 외의 권리자의 허가 없이 복제,
        전송, 대중에게 전송, 변경, 고객의 웹사이트에 옮기는 행위는 법률로
        금지되어 있습니다.{" "}
      </div>
      <div className="mt-4 whitespace-nowrap">6. 준거법 및 관할 법원</div>
      <div className="self-start mt-2 ml-4 text-xs font-light leading-5">
        이 ‘이메일 주소/WeChat ID의 이용 및 정보 전송 서비스에 대하여’에 정하는
        사항은 일본법에 준거하는 것으로, 그 내용이나 운영에 관한 분쟁이 발생한
        경우는 오사카지방법원을 제1심 관할 법원으로 정합니다.
      </div>
    </>
  );
};

export default AgreementKorean;
