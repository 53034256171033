/* eslint-disable react/jsx-no-comment-textnodes */
import { useCallback, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import home from "assets/images/home.svg";
import userInfo from "assets/images/icon/user.png";
import arrowLeft from "assets/images/arrow-left.svg";
import { GET_DETAIL, GET_USER_INFO, POST_USER_INFO, VIP_USER_REGISTER } from "services/users";
import { BeatLoader } from "react-spinners";
import { formatBirthdate } from "../utils/helper";
import Dropdown from "component/Dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useHandleLogout from "hooks/useHandleLogout";
import { setMemberInfo } from "store/member/memberSlice";
import { setInvalidMemberInfo } from "store/member/invalidMemberSlice";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

const Layout = (props) => {
  const {
    children,
    column,
    isShowButtonBack,
    isShowButtonHome = true,
    isShowUserInfo = false,
    title,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [language, setlanguage] = useState("ja");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCoupon, setIsCoupon] = useState(false);
  const [isFetchUserInfo, setIsFetchUserInfo] = useState(false);
  const handleLogout = useHandleLogout();
  const location = useLocation();
  const { t } = useTranslation();

  let errorToastId = null;

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    setlanguage(lang);
  }, [localStorage.getItem("lang")]);

  const onRegisterUser = async () => {
    const qrcode =
      localStorage.getItem("qrcode") &&
      JSON.parse(localStorage.getItem("qrcode"));

    const body = {
      member_no: qrcode?.member_no,
      rank: qrcode?.rank,
      signature: qrcode?.signature,
    };
    VIP_USER_REGISTER(body)
      .then((res) => {
        const data = res?.data;
        if (data) {
          const isOldMember = data?.active_date;
          const getBirthdate = formatBirthdate(data?.birthday).split("-");
          dispatch(
            setMemberInfo({
              isMember: true,
              member_no: data?.member_no,
              rank: data?.rank,
              oldMember: isOldMember,
              first_name: data?.first_name,
              middle_name: data?.middle_name,
              last_name: data?.last_name,
              birthdate: data?.birthdate,
              card_status: data?.card_status,
              day: getBirthdate[0] || "",
              month: getBirthdate[1] || "",
              year: getBirthdate[2] || "",
              ...body,
            })
          );

          dispatch(
            setInvalidMemberInfo({
              error: false,
            })
          );

          const userInfo = location.pathname === "/user-info";
          if (!userInfo) {
            if (data?.card_status === "invalid" && data?.first_name) {
              navigate("/membership-privilege");
            } else if (data?.card_status === "valid") {
              navigate("/membership");
            } else if (data?.card_status !== "active") {
              navigate("/");
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
        if (errorToastId === null) {
          // Show the error toast
          const toastOptions = {
            duration: Infinity,
            onClose: () => {
              // Reset the errorToastId when the toast is closed
              errorToastId = null;
            },
          };
          let errorMessage = ""
          if(error.status == 401){
            errorMessage = t("bad_request_401")
          }
          if(error.status == 400){
            errorMessage = t("bad_request_400")
            if (error?.code === 101) {
              errorMessage = t("bad_request_101")
            }
            if (error?.code === 102) {
              errorMessage = t("bad_request_102")
            }
          }
          errorToastId = toast.error(
            errorMessage || "Some error occurred",
            toastOptions
          );

          // sent error to sentry
          if (typeof error === "object") {
            console.error("User registration", JSON.stringify(error));
          } else {
            console.error("User registration", error);
          }

          dispatch(
            setInvalidMemberInfo({
              error: true,
            })
          );
          dispatch(setMemberInfo());
          if (error?.code === 102) {
            setTimeout(() => {
              handleLogout();
            }, 5000);
          }
        }
      });
  };

  const onGetDetail = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await GET_DETAIL();
      const data = res?.data[0];
      const qrcode =
        localStorage.getItem("qrcode") &&
        JSON.parse(localStorage.getItem("qrcode"));

      dispatch(
        setInvalidMemberInfo({
          error: false,
        })
      );
      if (Object.keys(data).length > 0 && !qrcode) {
        getDetailInfo(data);
      } else if (Object.keys(data).length > 0 && data?.member_no === qrcode?.member_no) {
        getDetailInfo(data);
      } else {
        onRegisterUser();
      }
    } catch (error) {
      toast.error(error.message || "Some error occurred");
      // sent error to sentry
      if (typeof error === "object") {
        console.error("Get user detail info", JSON.stringify(error));
      } else {
        console.error("Get user detail info", error);
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [dispatch, navigate]);

  const getDetailInfo = (data) => {
    const getBirthdate = formatBirthdate(data?.birthdate).split("-");
    dispatch(
      setMemberInfo({
        isMember: true,
        first_name: data?.first_name,
        middle_name: data?.middle_name,
        last_name: data?.last_name,
        birthdate: data?.birthdate,
        member_no: data?.member_no,
        rank: data?.rank,
        card_status: data?.card_status,
        day: getBirthdate[0] || "",
        month: getBirthdate[1] || "",
        year: getBirthdate[2] || "",
      })
    );

    const homepage = location.pathname === "/";
    if (data?.card_status === "active" && homepage) {
      navigate("/membership");
    } else if (data.card_status === "invalid" && data.first_name && homepage) {
      navigate("/membership-privilege");
    } else if (!data.card_status) {
      navigate("/");
    }

    if (!data?.first_name) {
      navigate("/");
    }
  };

  const setUserInfo = (data) => {
    localStorage.setItem('userInfo', JSON.stringify(data))
  }

  // useEffect(() => {
  //   onGetDetail();
  // }, [onGetDetail]);
  
  useEffect(() => {
    // get & post userInfo client
    const getUserInfoFetch = async () => {
      try {
        const res = await GET_USER_INFO();
        const getUserInfo = res?.data;
        if (getUserInfo) {
          setUserInfo(getUserInfo);
        }
        setIsFetchUserInfo(true)
        onGetDetail();
      } catch (error) {
        if(error.status == 404){
          const res = await POST_USER_INFO();
          if(res?.success == true){
            // setUserInfo(res?.data);
            onGetDetail();
          }
        }else{
          console.log(error)
          setTimeout(() => {
            handleLogout();
          }, 1000);
        }
      }
    }

    if (!isFetchUserInfo) getUserInfoFetch();

  }, [isFetchUserInfo]);

  const onChangeLang = () => {
    setIsLoading(true);
    setLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setLoading(false);
    }, 1000);
  };

  const HomeButton = () => {
    return (
      <img
        alt="arrow-left"
        loading="lazy"
        src={home}
        className="my-auto aspect-[0.95] fill-neutral-700 w-[19px] cursor-pointer"
        onClick={() => navigate("/membership")}
      />
    );
  };

  const UserInfoButton = () => {
    return (
      <div className="cursor-pointer bg-[white] w-[30px] h-7 flex rounded-3xl">
        <img
          alt="arrow-left"
          loading="lazy"
          src={userInfo}
          className="m-auto fill-neutral-700 w-[14px] !cursor-pointer hover:scale-105"
          onClick={() => navigate("/user-info")}
        />
      </div>
    );
  };

  return (
    <div
      className={`${
        loading
          ? "absolute inset-0 cursor-not-allowed pointer-events-none"
          : "font-primary"
      }`}
    >
      {(isLoading || loading) && (
        <span className="!flex loader">
          <BeatLoader size={20} color={"#404040"} />
        </span>
      )}
      <div
        className={`${
          loading || isLoading
            ? "opacity-20 cursor-not-allowed pointer-events-none"
            : ""
        } w-full lg:max-w-mobile m-auto bg-white overflow-hidden gap-30 font-primary min-h-screen`}
      >
        <div
          className={`flex flex-col items-center pt-4 pb-24 font-semibold ${
            isCoupon ? "bg-[#fafafa]" : "bg-primary-100"
          } w-full lg:max-w-mobile min-h-screen`}
        >
          <div className="flex w-full px-4">
            {isShowButtonHome && column === 2 && <HomeButton />}
            <div
              class={`${
                column === 2
                  ? "flex ml-auto mr-0 my-0"
                  : "grid grid-cols-3 w-full"
              } gap-4 items-center`}
            >
              {isShowUserInfo && <UserInfoButton />}
              {column !== 2 && !isShowUserInfo && (
                <div
                  class={`${
                    isShowButtonBack && "w-48"
                  } min-w-0 flex justify-start`}
                >
                  {isShowButtonBack && (
                    <div
                      className="flex items-center gap-2 cursor-pointer w-48"
                      onClick={() => navigate("/membership")}
                    >
                      <img
                        alt="arrow-left"
                        loading="lazy"
                        src={arrowLeft}
                        className="my-auto w-1.5 aspect-[0.5] stroke-[2px] stroke-neutral-700"
                      />
                      {isCoupon ? "GUEST COUPON" : "阪急VIP会員"}
                    </div>
                  )}
                  {isShowButtonHome && <HomeButton />}
                </div>
              )}
              <div class="min-w-0 m-auto">
                <div className="grow text-sm uppercase">
                  {!isShowButtonBack ? title : ""}
                </div>
              </div>
              <div class="min-w-0 flex justify-end">
                <Dropdown handleChange={onChangeLang} />
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: "toaster",
        }}
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};

export default Layout;
