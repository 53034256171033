import axios from 'axios'
import { LOCALSTORAGE } from 'common/constants'

const usingLocal = false

export const BASE_URL = usingLocal ? 'http://localhost:4500' : process.env.REACT_APP_BASE_SERVER_URL

let configFile = {
  headers: {
    'Content-type': 'multipart/form-data'
  }
}

const xOrigin = process.env.REACT_APP_BASE_SERVER_URL
let configStripe = {
  headers: {
    'Content-type': 'application/json',
    'X-origin': xOrigin
  }
}

// this is inteceptor for handling header API request 
axios.interceptors.request.use(
  (config) => {
    let token = ''
    token = localStorage.getItem(LOCALSTORAGE.TOKEN)
    if (token) config.headers.authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/** --------------------------------------------------------------------------------- */
/** ================================= AXIOS ACTIONS ================================= */
/** --------------------------------------------------------------------------------- */

export const ACTION_GET = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        if (err.response.data.code === 4005) {
          localStorage.clear()
          window.location.href = "/"
        }
        reject({ ...err.response.data, status: err.response.status })
      })
  })
}

export const ACTION_POST = (url, param) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, param)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject({ ...err.response.data, status: err.response.status })
      })
  })
}

export const ACTION_POST_FILE = (url, param) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, param, configFile)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject({ ...err.response.data, status: err.response.status })
      })
  })
}

export const ACTION_POST_STRIPE = (url, param) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, param, configStripe)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject({ ...err.response.data, status: err.response.status })
      })
  })
}

export const ACTION_PATCH = (url, param) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, param)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject({ ...err.response.data, status: err.response.status })
      })
  })
}

export const ACTION_PUT = (url, param) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, param)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject({ ...err.response.data, status: err.response.status })
      })
  })
}

export const ACTION_DELETE = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject({ ...err.response.data, status: err.response.status })
      })
  })
}
