// memberSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  memberInfo: {
    isMember: false,
    // active: false, 
    name: null,
    birthdate: null,
    day: null,
    month: null,
    year: null,
    member_no: null,
    rank: null,
    card_status: null,
    oldMember: false
  },
};

const memberSlice = createSlice({
  name: "member",
  initialState, // Use the initialState defined above
  reducers: {
    setMemberInfo: (state, action) => {
      state.memberInfo = action.payload;
    },
  },
});

export const { setMemberInfo } = memberSlice.actions;
export default memberSlice.reducer;
