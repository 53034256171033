import * as Constants from "common/constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import momentTz from "moment-timezone";

const Coupon = (props) => {
  const { type } = props;
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const { t } = useTranslation();
  const [card, setCard] = useState();
  const [lang, setLang] = useState();
  const [userTime, setUserTime] = useState("");
  const [barcode, setBarcode] = useState("");

  useEffect(() => {
    const userTZ = momentTz.tz.guess();
    const currentTime = momentTz().tz(userTZ).format("YYYY/MM/DD HH:mm:ss");
    setUserTime(currentTime);
    if (memberInfo.member_no) {
      setBarcode(memberInfo?.member_no.replace(/-/g, ""));
    }
    const timerID = setInterval(() => {
      const newTime = momentTz().tz(userTZ).format("YYYY/MM/DD HH:mm:ss");
      setUserTime(newTime);
    }, 1000);
    return () => clearInterval(timerID);
  }, []);

  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, [localStorage.getItem("lang")]);

  useEffect(() => {
    if (type) {
      const { memberFront, memberFrontKr } = Constants[capitalizedText(type)];
      setCard(lang === "en" ? memberFront : memberFrontKr);
    }
  }, [type, lang]);

  const capitalizedText = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <>
      <div className="px-4 self-stretch mt-6 text-sm leading-5 whitespace-nowrap">
        阪急百貨店　阪神百貨店
      </div>
      <div className="mt-6 text-sm leading-8">
        {memberInfo?.rank && t(`${memberInfo?.rank}_card`)}
      </div>
      <div className="text-2xl text-center mt-4 text-[#404040]">
        {memberInfo.first_name ? (memberInfo?.first_name+' '+(memberInfo.middle_name ? memberInfo.middle_name+' '+memberInfo.last_name : memberInfo.last_name )) :  "-"}
      </div>
      <p className="text-red-500 font-semibold text-[26px]">{userTime}</p>
      <div className="px-4">
        {memberInfo?.card_status !== "invalid" && (
          <div className="grid gap-1 mb-6 text-center">
            <Barcode
              value={barcode}
              format="codabar"
              width={2.2}
              className="m-auto"
              displayValue={false}
            />
            <p>{memberInfo?.member_no}</p>
          </div>
        )}
        {memberInfo?.card_status === "invalid" && (
          <div className="justify-center px-10 py-2 text-sm font-semibold leading-5 text-red-500 whitespace-nowrap bg-rose-100 rounded text-center">
            {t("membership_cannot_used")}
          </div>
        )}
      </div>
      <div className="py-6">
        <img
          alt="front"
          loading="lazy"
          srcSet={card}
        />
      </div>
      <div className="mt-4 text-lg leading-7 underline">販売員の皆様へ</div>
      <div className="mt-4 px-4 text-xs font-light leading-5">
        • 本カードは「海外顧客VIPクラブ」会員専用の優待カードです。
        <br />• 買上金額清算のため、優待対象外売場でもスキャンしてください。
      </div>
    </>
  );
};

export default Coupon;
