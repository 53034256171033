import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Email from "./Email";
import HandlingService from "./HandlingService";
import PriorityTax from "./PriorityTax";
import Carousel from "nuka-carousel";
import "./PopupInfo.css";

const PopupInfo = (props) => {
  const { type, open, popupIndex, handleClose } = props;

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-mobile transform overflow-hidden rounded-2xlleft align-middle shadow-xl text-left transition-all">
                  <div className="flex flex-col px-6 py-8 rounded-3xl bg-primary-100 max-w-[327px]">
                    <button
                      type="button"
                      className="absolute right-6 top-4 font-semibold text-[primary-100] cursor-pointer outline-none"
                      onClick={handleClose}
                    >
                      X
                    </button>
                    <Carousel
                      renderCenterLeftControls={({ previousSlide }) => null}
                      renderCenterRightControls={({ nextSlide }) => null}
                      pagingDotsClassName="bg-red"
                      slideIndex={popupIndex}
                    >
                      {type === "black" && <HandlingService type={type} />}
                      <PriorityTax type={type} />
                      <Email type={type} />
                    </Carousel>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupInfo;
