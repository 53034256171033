
import moment from 'moment';
export const date = () => {
    let numbers = [];
    for (let i = 0; i < 31; i++) {
      numbers.push(i+1);
    }
    return numbers;
}

export const month = () => {
    let numbers = [];
    for (let i = 0; i < 12; i++) {
      numbers.push(i+1);
    }
    return numbers;
}

export const year = () => {
    let numbers = [];
    for (let i = 2024; i >= 1920; i--) {
      numbers.push(i+1);
    }
    return numbers;
}
export const formatBirthdate = (date) => {
  const parsedDate = moment(date, 'YYYYY-MM-DD');    
  const formattedDate = parsedDate.format('D-M-YYYY');
  return formattedDate
}