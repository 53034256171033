import React, { useCallback, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "pages/Home";
import MembershipPrivilege from "pages/MembershipPrivilege";
import Membership from "pages/Membership";
import MembershipAgreement from "pages/MembershipAgreement";
import MembershipCoupon from "pages/MembershipCoupon";
import NewMemberCoupon from "pages/NewMemberCoupon";
import Login from "pages/Login";
import { Provider } from "react-redux";
import store from "./store";
import loginImage from "assets/images/login.jpg";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import "./styles/globals.css";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/user-info",
    element: <Home />,
  },
  {
    path: "/membership-privilege",
    element: <MembershipPrivilege />,
  },
  {
    path: "/membership",
    element: <Membership />,
  },
  {
    path: "/membership-agreement",
    element: <MembershipAgreement />,
  },
  {
    path: "/membership-coupon",
    element: <MembershipCoupon />,
  },
  {
    path: "/new-member-coupon",
    element: <NewMemberCoupon />,
  },
]);

const RootProvider = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("__token__")
  );
  const [isLoading, setIsLoading] = useState(true); // State to control loading
  const [showLogin, setShowLogin] = useState(false); // State to control login UI visibility
  
  const qrCodeParams = async () => {
    const params = await queryParams(); // Get query params URL
    if (params?.member_no) // if param has member_no then set localStorage qrcode
      localStorage.setItem("qrcode", JSON.stringify(params));
  };

  const queryParams = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    // Looping to get queries params
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };

  const getUserInfo = useCallback(async () => {
    const code = window?.location?.href?.includes("code"); //  Get code from query param URL
    try {
      setIsLoading(true); // Show loading state
      const getToken = localStorage.getItem("__token__");  // Get access token from auth0
      const params = await queryParams(); // Get query params URL
      if (getToken && params?.member_no) { // if param has member_no and has token then auto logout to trigger login again, this function for upgrade member such as silver to gold etc
        handleLogout();
      } else {
        let token = await getAccessTokenSilently();  // Get access token from auth0
        if (token) {
          localStorage.setItem("__token__", token);
          Sentry.setUser({ id: token.id_token, username: token.id_token });  // Set sentry user info
          setAccessToken(token);     
        } else {
          // when failed to get access token we will show the login UI and set localstorage from params URL
          await qrCodeParams();
          setShowLogin(true);
        }
      }
    } catch (err) {
      console.error("Error getting token silently:", err);
      if (!code) { // If URL doesn't have a code query param then show the login UI
        await qrCodeParams();
        if (err?.error === "missing_refresh_token") { // Only error missing_refresh_token will be auto redirected to the login
          loginWithRedirect()
          // setShowLogin(true);
        } else{ // Others errors such as timeout while get token etc will be shown the landing page
          setShowLogin(true); // Show custom login UI on error
        }
      }
    } finally {
      setIsLoading(false); // Ensure loading stops after token check
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const handleLogout = async () => {
    // Logout from auth0 client
    const url = window.location.origin;
    localStorage.clear();
    window.open(
      `${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/v2/logout?client_id=${
        process.env.REACT_APP_OTP_CLIENT_ID
      }&returnTo=${encodeURIComponent(url)}&direct_logout=true`,
      "_self"
    );
    const params = await queryParams();
    if (params?.member_no)
      localStorage.setItem("qrcode", JSON.stringify(params));
  };

  // Show Loading UI
  if (isLoading) {
    return (
      <div className="w-full h-screen flex flex-col justify-center">
        <div aria-label="Loading..." role="status" className="mx-auto">
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 animate-spin stroke-slate-500"
          >
            <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
          </svg>
        </div>
      </div>
    );
  }

  // Show Landing page UI
  if (showLogin && !isLoading) {
    return (
      <div className="flex flex-col items-center gap-32 font-semibold w-full lg:max-w-mobile min-h-screen m-auto relative">
        <img className="mt-6 h-52" loading="lazy" srcSet={loginImage} alt="login" />
        <div className="px-4 w-full flex">
          <button
            className="bg-black rounded-full p-2 text-white text-base w-1/2 mx-auto"
            onClick={() => loginWithRedirect()}
          >
            Back to Login
          </button>
        </div>
      </div>
    );
  }

  // Get router page
  return (
    <Provider store={store}>
      {accessToken && <RouterProvider router={router} />}
    </Provider>
  );
};

export default RootProvider;
