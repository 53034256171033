import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setMemberInfo } from "store/member/memberSlice";

const useHandleLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = useCallback(async () => {
    const url = window.location.origin;    
    dispatch(
      setMemberInfo()
    );
    localStorage.clear();
    window.open(
      `${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/v2/logout?client_id=${
        process.env.REACT_APP_OTP_CLIENT_ID
      }&returnTo=${encodeURIComponent(url)}&direct_logout=true`,
      "_self"
    );
  }, []);

  return handleLogout;
};

export default useHandleLogout;
