// invalidMemberSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invalidMemberInfo: {
    error: false,
  },
};

const invalidMemberSlice = createSlice({
  name: "invalidMember",
  initialState, // Use the initialState defined above
  reducers: {
    setInvalidMemberInfo: (state, action) => {
      state.invalidMemberInfo = action.payload;
    },
  },
});

export const { setInvalidMemberInfo } = invalidMemberSlice.actions;
export default invalidMemberSlice.reducer;
