import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import RootProvider from "./RootProvider";

const App = () => {
  const onRedirectCallback = async (appState) => {
    window.location.href = "/";
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}
      clientId={process.env.REACT_APP_OTP_CLIENT_ID}
      useCookiesForTransactions
      cacheLocation="localstorage"
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <RootProvider />
    </Auth0Provider>
  );
};

export default App;
