/* eslint-disable react/jsx-no-comment-textnodes */
import Coupon from "component/Coupon";
import Layout from "./Layout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const MembershipCoupon = () => {
  const navigate = useNavigate();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  
  useEffect(() => {
    if (memberInfo?.card_status === "invalid") navigate("/membership-privilege");
  }, [memberInfo, navigate]);
  
  return (
    <Layout title="阪急阪神百貨店 VIP会員" column={2} isShowButtonHome={true}>      
       <Coupon type={memberInfo?.rank} />
    </Layout>
  );
};

export default MembershipCoupon;
