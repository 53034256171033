/* eslint-disable react/jsx-no-comment-textnodes */
import MemberAgreement from "component/MemberAgreement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import couponImage from "assets/images/coupon.svg";
import vipGold from "assets/images/vip-gold.svg";
import vipSilver from "assets/images/vip-silver.svg";
import Layout from "./Layout";

const Home = () => {
  const [isCoupon, setIsCoupon] = useState(false);
  const { t } = useTranslation();
  
  return (
    <Layout title={isCoupon ? 'Guest Coupon' : '阪急VIP会員'} isShowButtonHome={false}>
      {isCoupon ? (
        <img loading="lazy" srcSet={couponImage} className="mt-4" alt="coupon" />
      ) : (
        <div className="flex gap-4 px-2 mb-2 justify-between self-stretch mt-4 text-sm leading-5 whitespace-nowrap">
          <div className="flex flex-col flex-1 items-center text-zinc-500">
            <div className="justify-center px-2.5 py-1.5 border-b-2 border-solid border-b-[#848484]">
              {t("silver_member")}
            </div>
            <img
              loading="lazy"
              src={vipSilver}
              className="mt-2 aspect-[1.47] w-[172px]"
              alt="vip-silver"
            />
          </div>
          <div className="flex flex-col flex-1 items-center text-[#c69939]">
            <div className="justify-center px-2.5 py-1.5 border-b-2 border-solid border-b-[#c69939]">
              {t("gold_member")}
            </div>
            <img
              loading="lazy"
              src={vipGold}
              alt="vip-silver"
              className="mt-2 aspect-[1.47] w-[171px]"
            />
          </div>
        </div>
      )}
      <MemberAgreement isCoupon={isCoupon} />
    </Layout>
  );
};

export default Home;
