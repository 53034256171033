import React, { useEffect, useState } from "react";
import PopupInfo from "./Popup/PopupInfo";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Silver, Black, Gold } from "common/constants";
import getColor from "utils/getColor";

const MembershipCard = (props) => {
  const { cardType = "gold" } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Preload images
    const preloadImages = () => {
      const imagesToPreload = [
        cardType === "gold"
          ? Gold.card
          : cardType === "black"
          ? Black.card
          : Silver.card,
        cardType === "gold"
          ? Gold.freeService
          : cardType === "black"
          ? Black.freeService
          : Silver.freeService,
        cardType === "gold"
          ? Gold.memberPrivilege
          : cardType === "black"
          ? Black.memberPrivilege
          : Silver.memberPrivilege,
      ];

      imagesToPreload.forEach((imageSrc) => {
        const img = new Image();
        img.src = imageSrc;
      });
    };

    preloadImages();
  }, [cardType]);

  const handleOpen = (index) => {
    setPopupIndex(index);
    setOpenPopup(true);
  };

  const onRedirectMemberAgreement = () => {
    navigate("/membership-agreement");
  };

  const PriorityTax = () => {
    return (
      <div
        className="flex flex-col flex-1 self-stretch cursor-pointer hover:scale-105"
        onClick={() => handleOpen(cardType === "black" ? 1 : 0)}
      >
        <img
          alt="priority_tax"
          loading="lazy"
          srcSet={
            cardType === "gold"
              ? Gold.tax
              : cardType === "black"
              ? Black.tax
              : Silver.tax
          }
          className="self-center w-16 aspect-square"
        />
        <div className="mt-1" style={{ color: getColor(cardType)}}>
          {t("priority_tax")}
        </div>
      </div>
    );
  };
  
  return (
    <>
      <img
        alt="card"
        loading="lazy"
        srcSet={
          cardType === "gold"
            ? Gold.card
            : cardType === "black"
            ? Black.card
            : Silver.card
        }
        className="mt-4 w-full"
      />

      <div className="flex flex-col px-6 mt-8 w-full text-center" style={{ color: getColor(cardType)}}>
        <div className="text-sm leading-5">
          {t("vip_overseas_membership")}
        </div>
        <div
          onClick={() => navigate("/membership-privilege")}
          className="justify-center px-4 py-3 mt-2 text-sm leading-5 whitespace-nowrap rounded-lg border-2 border-solid hover:scale-105 cursor-pointer"
          style={{ borderColor: getColor(cardType)}}
        >
          {t("vip_overseas_info")}
        </div>
      </div>
      
      <div className="flex gap-0 items-start self-center mt-6 w-full text-xs leading-4 text-center max-w-[300px]">
        {cardType === "black" && (
          <div
          className="flex flex-col flex-1 self-stretch cursor-pointer hover:scale-105"
          onClick={() => handleOpen(0)}
          >
            <img
              alt="free_luggage"
              loading="lazy"
              srcSet={
                cardType === "gold"
                  ? Gold.freeService
                  : cardType === "black"
                  ? Black.freeService
                  : Silver.freeService
              }
              className="m-auto w-16 aspect-square"
            />
            <div className="mt-1" style={{ color: getColor(cardType)}}>{t("free_luggage")}</div>
          </div>
        )}
        {cardType === "black" && <PriorityTax />}
      </div>
      <div className="flex gap-0 items-start self-center mt-6 w-full text-xs leading-4 text-center max-w-[300px]">
        {cardType !== "black" && <PriorityTax />}
        <div
          className="flex flex-col flex-1 cursor-pointer hover:scale-105"
          onClick={() =>
            (window.location.href =
              "mailto:hankyu-vip@hankyu-hanshin-dept.com")
          }
        >
          <img
            alt="customer_service"
            loading="lazy"
            srcSet={
              cardType === "gold"
                ? Gold.email
                : cardType === "black"
                ? Black.email
                : Silver.email
            }
            className="self-center w-16 aspect-square"
          />
          <div className="mt-1" style={{ color: getColor(cardType)}}>{t("customer_service")}</div>
        </div>
        <div
          className="flex flex-col flex-1 cursor-pointer hover:scale-105"
          onClick={onRedirectMemberAgreement}
        >
          <img
            alt="members_agreement"
            loading="lazy"
            srcSet={
              cardType === "gold"
                ? Gold.memberAgreement
                : cardType === "black"
                ? Black.memberAgreement
                : Silver.memberAgreement
            }
            className="self-center w-16 aspect-square"
          />
          <div className="mt-1" style={{ color: getColor(cardType)}}>{t("members_agreement")}</div>
        </div>
      </div>
      <div
        onClick={() => navigate("/membership-coupon")}
        className="px-4 mt-8 w-full cursor-pointer"
      >
        <div
          className={`justify-center py-3 self-center text-sm text-center text-white whitespace-nowrap w-full
      ${
        cardType === "gold"
          ? "bg-primary-300"
          : cardType === "black"
          ? "bg-black"
          : "bg-[#A9AAAB]"
      } rounded-lg hover:scale-105 hover:cursor-pointer`}
        >
          {t("use_membership")}
        </div>
      </div>

      <PopupInfo
        type={cardType}
        open={openPopup}
        popupIndex={popupIndex}
        handleClose={() => setOpenPopup(false)}
      />
    </>
  );
};

export default MembershipCard;
