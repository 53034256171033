import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import globe from "assets/images/globe.svg";

const Dropdown = (props) => {
  const { handleChange } = props;
  const { t, i18n } = useTranslation();
  const [language, setlanguage] = useState("en");

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    lang === "kr" ? setlanguage("kr") : setlanguage("en");
  }, [language]);

  const changeLanguage = () => {
    const setupLang = language === "en" ? "kr" : "en";
    i18n.changeLanguage(setupLang);
    setlanguage(setupLang);
    localStorage.setItem("lang", setupLang);
    handleChange(true);
  };

  return (
    <>
      <div className="!cursor-pointer relative flex gap-2 justify-between p-1 text-xs bg-white rounded-[32px] items-center pl-2 pr-1 py-1 w-[94px]">
        <Menu as="div">
          <Menu.Button className="flex items-center gap-1 text-center">
            <img
              alt="lang"
              loading="lazy"
              src={globe}
              className="h-[18px] aspect-square fill-neutral-700"
            />
            {language === "en" ? "English" : "한국어"}
            <ChevronDownIcon
              className="h-4 w-4 text-black hover:text-gray-800"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-24 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-primary-200 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-xs justify-center`}
                      onClick={changeLanguage}
                    >
                      {language !== "en" ? "English" : "한국어"}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export default Dropdown;
