import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMemberInfo } from "store/member/memberSlice";
import { toast } from "react-hot-toast";
import { useCallback } from "react";
import { GET_DETAIL } from "services/users";
import { formatBirthdate } from "../utils/helper";

const PrivilegeEnglish = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const handleCheckActivation = () => {
    onGetDetail();
  };

  const onGetDetail = useCallback(async () => {
    try {
      const res = await GET_DETAIL();
      const data = res?.data[0];
      if (data) {
        const getBirthdate = formatBirthdate(data?.birthdate).split("-");
        dispatch(
          setMemberInfo({
            isMember: true,
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            birthdate: data?.birthdate,
            member_no: data?.member_no,
            rank: data?.rank,
            card_status: data?.card_status,
            day: getBirthdate[0] || "",
            month: getBirthdate[1] || "",
            year: getBirthdate[2] || "",
          })
        );

        if (data?.card_status === "active") {
          navigate("/membership");
        } else {
          toast.error("User doesn't active!");
          console.error("User doesn't active!");
        }
      } else {
        toast.error("User doesn't active!");
        console.error("User doesn't active!");
      }
    } catch (error) {
      toast.error(error.message || "Some error occurred");
      // sent error to sentry
      if (typeof error === "object") {
        console.error("Get user detail info", JSON.stringify(error));
      } else {
        console.error("Get user detail info", error);
      }
    }
  }, [dispatch, navigate]);

  return (
    <div className="px-2">
      <div className="justify-center p-4 w-full mt-8 text-xs text-orange-800 border-2 border-orange-800 border-solid">
        <span className="word-break">
          Tax refund customers with a cumulative <br /> shopping amount of 1
          million yen (before tax) in a year can join.
        </span>
      </div>
      <div className="justify-center p-2 w-full mt-8 text-sm text-white bg-primary-300">
        ■ Member Privileges
      </div>
      <div className="flex flex-col px-2 mt-4 text-sm leading-5 text-neutral-700">
        <div>◇7% off all purchases for Silver Card holders </div>
        <div className="mt-2">◇10% off all purchases for Gold Card holders</div>
        <div className="mt-2">
          Customers who purchase more than 5 million yen worth of goods
          (excl.tax) will be upgraded to Gold Card Membership{" "}
        </div>
        <div className="mt-2 pl-9">
          *After the Silver Card is issued, accumulation of the total purchase
          amount will start <br />
          *VIP card discounts are still eligible for tax refunds <br />
          *VIP cards cannot be used in conjunction with other privileges
          (shareholder coupon, GUEST COUPON, point cards, etc) <br />
          *Not all items and brands are eligible <br />
          *Please be sure to present your card when making your purchase. <br />
          We will accumulate the purchase amount. Also, accumulation can not be
          made after the payment <br />
          *These services are only available at 6 stores
        </div>
      </div>
      <div className="justify-center p-2 w-full mt-8 text-sm text-white bg-primary-300">
        ■ Special services for Members
      </div>
      <div className="flex flex-col px-2 mt-4 text-sm leading-5 text-neutral-700">
        <div>
          ①Priority tax refund processing (only at Hankyu Umeda Main Store,
          Hankyu Men's Osaka, Hankyu Men's Tokyo)
        </div>
        <div className="mt-2">
          ②One-stop pickups for all purchases (only at Hankyu Umeda Main Store)
        </div>
        <div className="mt-2">
          ③Provision of useful information about events, etc (Only from Hankyu
          Umeda Main Store; irregular)
        </div>
        <div className="mt-2">
          ④Free luggage handling service (only at Hanshin Umeda Main Store)
        </div>
        <div className="mt-6">Contact Us</div>
        <div className="mt-2 pl-9">
          8-7 Kakuda-cho,Kita-ku,Osaka <br />
          530-8350 <br />
          Tel:81-6-6361-1381 <br />
          Ｅ-mail：hankyu-vip＠
          <a
            href="http://hankyu-hanshin-dept.com/"
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            hankyu-hanshin-dept.com
          </a>
        </div>
      </div>
      <div className="justify-center p-2 w-full mt-8 text-sm text-white bg-primary-300">
        ■ Stores with Special Services
      </div>
      <div className="flex flex-col px-2 mt-4 text-sm text-neutral-700">
        <div className="flex gap-3 justify-between px-1.5 leading-5">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="flex-auto">
            Hankyu Department Store Umeda Main Store
          </div>
        </div>
        <div className="flex gap-3 justify-between px-1.5 mt-2 leading-5">
          <div className="self-start mt-1.5 w-3 h-3 rounded-full bg-neutral-700" />
          <div className="flex-auto">
            Hanshin Department Store Umeda Main Store
          </div>
        </div>
        <div className="flex gap-3 justify-between px-1.5 mt-2 whitespace-nowrap">
          <div className="my-auto w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow">Kobe Hankyu</div>
        </div>
        <div className="flex gap-3 justify-between px-1.5 mt-2 whitespace-nowrap">
          <div className="my-auto w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow">Hakata Hankyu </div>
        </div>
        <div className="flex gap-3 justify-between px-1.5 mt-2 whitespace-nowrap">
          <div className="my-auto w-3 h-3 rounded-full bg-neutral-700" />
          <div className="grow">Hankyu Men's Osaka </div>
        </div>{" "}
        <div className="flex gap-3 justify-between px-1.5 mt-2 whitespace-nowrap">
          <div className="my-auto w-3 h-3 rounded-full bg-neutral-700" />{" "}
          <div className="grow">Hankyu Men's Tokyo </div>
        </div>
        <div className="mt-2 leading-5">
          *Some services are not available depending on the contents <br />
          *For other services, please visit the Tax Refund Counter at each store
        </div>
      </div>
      <div onClick={handleCheckActivation} className="frame cursor-pointer">
        <div className="text-wrapper">
          {memberInfo?.card_status === "active"
            ? "Back to home"
            : "The clerk will activate your membership card."}
        </div>
      </div>
    </div>
  );
};

export default PrivilegeEnglish;
